<script setup lang="ts">
import { type PropType, type Ref, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import type { BlogPost } from '~/types/blog.type';
import type { Pagination } from '~/types/pagination.type';
import HeaderLinks from './partials/header-links.vue';
import SubscribePanel from './partials/subscribe-panel.vue';
import BlogHeaderCard from './partials/blog-header-card.vue';
import BlogCard from './partials/blog-card.vue';
import AdBanner from './partials/ad-banner.vue';
import { useMetaStore } from '~/stores/meta.store';
import type { MetaAttributes } from '~/types/meta-attributes.type';

const props = defineProps({
	data: {
		type: Object as PropType<{ posts: BlogPost[], pagination: Pagination }>,
		required: true,
	},
	meta: {
		type: Object as PropType<MetaAttributes>,
		required: true
	},
	fetchData: {
		type: Function,
		required: true,
	},
});

const page: Ref<Number> = ref(Number(props.data?.pagination?.currentPage) || 1);
const pageData: Ref<{ posts: BlogPost[], pagination: Pagination }> = ref(props.data);

const router = useRouter();

const metaStore = useMetaStore();
const meta = ref({});
metaStore.getMetaTags()

meta.value = metaStore.metaGetter();
metaStore.setMeta(props.meta);

watch(() => props.data, () => pageData.value = props.data);

watch(page, async (newPage) => {
	// Update query
	await router.push({
		query: { page: newPage.toString() },
	});

	// Scroll to top
	window.scroll({ behavior: 'smooth', top: 0 });

	// Fetch and update new page data
	const { data } = await props.fetchData();
	if (data) {
		pageData.value = data;
	}
});
</script>

<template>
	<LayoutAppHead></LayoutAppHead>
	<NuxtLayout name="desktop-blog">
		<main>
			<section>
				<HeaderLinks :country_id="pageData.country_id" />
				<div class="container mx-auto max-w-7xl px-4 sm:px-2 lg:px-8 mb-8">
					<div class="gap-4 mx-0">
						<div class="col-span-3">
							<div class="p-3 bg-white rounded-xl border">
								<BlogHeaderCard v-if="pageData?.posts?.length" :data="pageData.posts[0]" />
								<div v-if="pageData?.posts?.length > 1" class="grid grid-cols-2 gap-4">
									<BlogCard v-for="(post, index) in pageData.posts.slice(1)"
										:key="`${post._id}-${index}`" :data="post" />
								</div>
								<div class="flex justify-center mt-4">
									<w-pagination v-model="page" :page-count="pageData?.pagination?.perPage"
										:total="pageData?.pagination?.total" size="md"/>
								</div>
							</div>
						</div>
						<div>
							<!-- <SubscribePanel /> -->
							<!-- <AdBanner /> -->
						</div>
					</div>
				</div>
			</section>
		</main>
	</NuxtLayout>
</template>
